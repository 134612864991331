/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import React, { useContext } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Menu } from '../../General';
import { TokenContext } from '../../../models/Customer';
import useStaticInfo from '../../../models/StaticInfo';

const cookies = new Cookies();
const { MenuRow, MenuBody, MenuItem } = Menu;

const Container = styled.div`
  padding: 2.34375rem 0 1.5rem;
  position: relative;
  background-color: #222222;
  z-index: 99;
  height: 283px;
  @media (max-width: 980px) {
    display: none;
  }
`;

const Width = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  display: block;
  padding-right: 15px;
  padding-left: 15px;
`;

function UpperFooter() {
  const { token, logout } = useContext(TokenContext);
  const { i18n } = useTranslation();
  const { StaticInfoContext, getStaticValue } = useStaticInfo();
  const staticInfo = useContext(StaticInfoContext);

  const pathNameData = getStaticValue(
    [
      'Pages-i-HOURLY Parking Service Tutorial & FAQ',
      'Pages-i-MONTHLY Parking Service Tutorial & FAQ',
    ],
    staticInfo
  );

  const homeList = [
    { link: `/${i18n.language}`, title: 'footer.Home', TopTitle: true },
    {
      chineseLink: 'https://wilsongrouphk.com/tc/',
      engLink: 'https://wilsongrouphk.com/en/',
      title: 'footer.Wilson_Group',
    },
    {
      chineseLink: 'http://www.mackcarpark.com.hk/big5/profile.php',
      engLink: 'http://www.mackcarpark.com.hk/eng/profile.php',
      title: 'footer.Mack_Parking',
    },
    {
      chineseLink: 'http://www.washandwax.com.hk',
      engLink: 'http://www.washandwax.com.hk',
      title: 'footer.Wash_Wax',
    },
    {
      chineseLink: 'https://www.wilsonstorage.com.hk/zh-hk/home.html',
      engLink: 'https://www.wilsonstorage.com.hk/en-hk/home.html',
      title: 'footer.Wilson_Storage',
    },
  ];

  const actionList = [
    { link: `/${i18n.language}/car_parks/map`, title: 'footer.FIND_CAR_PARK', TopTitle: true },
    {
      link: token ? `/${i18n.language}` : `/${i18n.language}/customers/login`,
      title: 'header.iMONTHLY',
      title2: token ? 'header.logout' : 'header.login',
      onClick: () => (token ? logout() : null),
    },
    {
      link: `/${i18n.language}/customers/login`,
      title: 'footer.Registration',
      state: 'registration',
    },
    { link: `/${i18n.language}/cart`, title: 'footer.Cart' },
    { link: `/${i18n.language}/customers/account`, title: 'footer.My_Account' },
    { link: `/${i18n.language}/customers/forgot-password`, title: 'footer.Forgot_Password' },
    {
      link: `/${i18n.language}/${pathNameData['Pages-i-MONTHLY Parking Service Tutorial & FAQ']}`,
      title: 'footer.iMonthlyFAQ',
    },
    // {
    //   link: `/${i18n.language}/car_parks/map`,
    //   title: 'footer.BOOK_iHOURLY',
    //   state: { bookIhourly: [11] },
    // },
    // {
    //   link: `/${i18n.language}/${pathNameData['Pages-i-HOURLY Parking Service Tutorial & FAQ']}`,
    //   title: 'footer.iHourlyFAQ',
    // },
  ];

  const aboutList = [
    { link: `/${i18n.language}/news/index`, title: 'footer.NEWS', TopTitle: true },
    { link: `/${i18n.language}/our-company`, title: 'footer.OUR_COMPANY' },
    { link: `/${i18n.language}/awards/index`, title: 'footer.AWARDS' },
  ];

  const csList = [
    {
      link: `/${i18n.language}/customer_enquiries/index`,
      title: 'footer.CUSTOMER_SERVICE',
      TopTitle: true,
    },
    {
      link: `/${i18n.language}/business_enquiries/index`,
      title: 'footer.BUSINESS_ENQUIRY',
    },
  ];

  return (
    <Container>
      <Width>
        <MenuRow>
          <MenuBody vertical="true">
            {homeList.map((item) => (
              <MenuItem {...item} key={`footer-${item.title}`} />
            ))}
          </MenuBody>
          <MenuBody vertical="true">
            {actionList.map((item) => (
              <MenuItem {...item} key={`footer-${item.title}`} />
            ))}
          </MenuBody>
          <MenuBody vertical="true">
            {aboutList.map((item) => (
              <MenuItem {...item} key={`footer-${item.title}`} />
            ))}
          </MenuBody>
          <MenuBody vertical="true">
            {csList.map((item) => (
              <MenuItem {...item} key={`footer-${item.title}`} />
            ))}
          </MenuBody>
        </MenuRow>
      </Width>
    </Container>
  );
}

export default UpperFooter;
