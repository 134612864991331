import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import findCarParkStyle from '../../../styles/findcarpark';
import ScreenWidthListener from '../../../components/General/ScreenWidthListener';

const iHourlyTutorial = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/carpark-detail/ihourly_tutorial.png`;
const iMonthlyTutorial = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/carpark-detail/imonthly_tutorial.png`;

const Container = styled.div`
  background: #d8d8d8;
  padding: ${(props) => props.padding};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: space-around;
`;

const Button = styled(Link)`
  background: none;
  border: none;
  display: flex;
  outline: none;
  text-decoration: none !important;
  color: #666;
  padding: 0 15px;
  font-size: 0.975rem;
  &:hover {
    color: #666;
  }
`;

const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background: #fff;
  margin: 10px 0;
`;

function Tutorial() {
  const { outerWidth } = ScreenWidthListener();

  const padding = outerWidth > 850 ? '25px 0 65px 0' : '10px 15px';
  const flexDirection = outerWidth > 850 ? 'row' : 'column';

  return (
    <Container padding={padding} flexDirection={flexDirection}>
      {outerWidth <= 850 && <DesktopTutorialLink />}
      {outerWidth > 850 && <MobileTutorialLink />}
    </Container>
  );
}

export default Tutorial;

function DesktopTutorialLink() {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <Button to={`/${i18n.language}/ihourly-tutorial-and-faq`}>
        <img
          alt="iHourlyTutorial"
          src={iHourlyTutorial}
          style={{ alignSelf: 'center', marginRight: 25 }}
          width="40"
        />
        <span style={{ alignSelf: 'center', fontSize: '0.975rem' }}>
          {t('CarParkDetail.iHourlyFAQ')}
        </span>
      </Button>

      <BreakLine /> */}

      <Button to={`/${i18n.language}/imonthly-tutorial-and-faq`}>
        <img
          alt="iMonthlyTutorial"
          src={iMonthlyTutorial}
          style={{ alignSelf: 'center', marginRight: 25 }}
          width="40"
        />
        <span style={{ alignSelf: 'center', fontSize: '0.975rem' }}>
          {t('CarParkDetail.iMonthlyFAQ')}
        </span>
      </Button>
    </>
  );
}

function MobileTutorialLink() {
  const { t } = useTranslation();

  return (
    <>
      {/* <Link to="/ihourly-tutorial-and-faq" style={findCarParkStyle.tutorialLinkButton}>
        <img
          alt="iHourlyTutorial"
          src={iHourlyTutorial}
          style={{ alignSelf: 'center', marginBottom: 10 }}
          width="70"
        />
        {t('CarParkDetail.iHourlyFAQ')}
      </Link> */}

      <Link to="/imonthly-tutorial-and-faq" style={findCarParkStyle.tutorialLinkButton}>
        <img
          alt="iMonthlyTutorial"
          src={iMonthlyTutorial}
          style={{ alignSelf: 'center', marginBottom: 10 }}
          width="70"
        />
        {t('CarParkDetail.iMonthlyFAQ')}
      </Link>
    </>
  );
}
