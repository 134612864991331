import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const bkground1 = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/home/promote2_bg.png`;
const whitearrow = `${process.env.REACT_APP_CP_MEDIA_DOMAIN}/img/icon-next-white.png`;

const Container = styled.div`
  display: flex;
  background-image: url(${bkground1});
  background-size: 150%;
  background-position: center bottom;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 71px 15px 114px 15px;

  @media (max-width: 850px) {
    padding: 40px 15px 40px 15px;
    background-size: 130%;
  }
`;

const Title = styled.span`
  font-size: 3rem;
  color: #333;
  line-height: 1.2;

  @media (max-width: 850px) {
    font-size: 30px;
  }
`;

const SubTitle = styled.span`
  font-size: 27.2px;
  color: #555;
  @media (max-width: 850px) {
    font-size: 20.8px;
  }
`;

const Text = styled.span`
  line-height: 1.2;
  color: #444;
  font-size: 1.5rem;

  @media (max-width: 850px) {
    font-size: 13.2px;
  }

  a {
    color: #fd980f;
    font-size: 1.2rem;

    &:hover {
      text-decoration-line: none;
      color: #fd980f;
    }

    @media (max-width: 850px) {
      font-size: 13.2px;
    }
  }
`;

const Margin = styled.div`
  margin-top: ${(props) => props.top};
  margin-bottom: ${(props) => props.bottom};
`;

const A = styled(Link)`
  color: #fd980f;
  font-size: 1.2rem;

  &:hover {
    text-decoration-line: none;
    color: #fd980f;
  }

  @media (max-width: 850px) {
    font-size: 13.2px;
  }
`;

const ExploreDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExploreA = styled.button`
  font-size: 1.2rem;
  position: relative;
  padding: 5px;
  color: #fd980f;
  line-height: 24px;
  text-align: right;
  min-width: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 35px;

  &:hover {
    text-decoration-line: none;
    color: #fd980f;
  }

  @media (max-width: 850px) {
    font-size: 13.2px;
  }

  &:after {
    position: absolute;
    left: auto;
    right: 4px;
    transition: inherit;
    display: inline-block;
    width: 24px;
    height: 24px;
    color: #ffffff;
    font-size: 0rem;
    line-height: 24px;
    text-align: center;
    background-repeat: no-repeat;
    content: '';
    background-image: url(${whitearrow});
    background-position: center;
    background-color: #fd980f;
    background-size: auto 55%;
    vertical-align: text-bottom;
    font-weight: bold;
    border-radius: 50px;
    background-clip: padding-box;
  }
`;

const TagLine = styled.p`
  font-size: 2rem;
  line-height: 1.3em;
  color: #fd980f;
  margin-top: 30px;
`;

const OnlineBooking = ({ data }) => {
  const { t, i18n } = useTranslation();

  const {
    booking_title,
    booking_imonthly_introduction,
    booking_imonthly_url,
    booking_ihourly_introduction,
    booking_tagline,
    booking_ihourly_url,
  } = data || {};

  return (
    <Container>
      <div style={{ maxWidth: 1024 }}>
        <Title dangerouslySetInnerHTML={{ __html: booking_title }}>
          {/* {t('Home.layer2Title')}
          <br />
          {t('Home.layer2Title1')}
          <br /> */}
        </Title>

        <TagLine>{booking_tagline}</TagLine>

        <Margin top="38px" />
        <SubTitle>
          {t('Home.layer2sub1')}
          <br />
        </SubTitle>
        <Margin top="30px" />
        <Text dangerouslySetInnerHTML={{ __html: booking_imonthly_introduction }}>
          {/* {t('Home.layer2text1')}
          <br />
          {t('Home.layer2text11')}
          <br /> */}
        </Text>
        {/* <A to={{ pathname: `/${i18n.language}/customers/login`, state: 'registration' }}>
          {t('Home.layer2href1')}
          <br />
        </A> */}
        <ExploreDiv>
          <ExploreA onClick={() => window.open(booking_imonthly_url, '_blank')}>
            {t('Home.layer2href2')}
          </ExploreA>
        </ExploreDiv>
        {/* <Margin top="40px" /> */}
        {/* <SubTitle>{t('Home.layer2sub2')}</SubTitle>
        <Margin bottom="20px" /> */}
        {/* <Text dangerouslySetInnerHTML={{ __html: booking_ihourly_introduction }}> */}
        {/* {' '}
          {t('Home.layer2text2')}
          <br />
          {t('Home.layer2text22')}
          <br /> */}
        {/* </Text> */}
        {/* <A to={`/${i18n.language}/car_parks/map`}>
          {t('Home.layer2href3')}
          <br />
        </A> */}
        {/* <ExploreDiv>
          <ExploreA onClick={() => window.open(booking_ihourly_url, '_blank')}>
            {t('Home.layer2href4')}
          </ExploreA>
        </ExploreDiv> */}
      </div>
    </Container>
  );
};

export default OnlineBooking;
