import { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { TokenContext } from './Customer';
import { CustomerContext } from './User';
import AlertIcon from '../assets/images/alarm_on.png';

const url = `${process.env.REACT_APP_API_DOMAIN}/WaitingList`;
const LIMIT = 20;

function useWaitingList(limit = LIMIT) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { token: userToken } = useContext(TokenContext);
  const {
    user: { id: customerId = '' },
  } = useContext(CustomerContext);
  const history = useHistory();

  const [waitingListLoading, setWaitingListLoading] = useState(true);
  const [waitingList, setWaitingList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  function changePage(e) {
    setPage(e);
  }

  function successAlert(reference, handleClose) {
    Swal.fire({
      imageUrl: AlertIcon,
      imageWidth: 80,
      imageHeight: 80,
      title: t('MyAccount.WaitingList.appliedSuccess'),
      position: 'center',
      html: t('MyAccount.WaitingList.pleaseWait'),
      confirmButtonColor: '#fd980f',
      confirmButtonText: t('MyAccount.WaitingList.goToMyPage'),
      showCancelButton: true,
      cancelButtonText: t('MyAccount.iMonthly.cancel'),
      cancelButtonColor: '#888',
      reverseButtons: true,
      customClass: {
        image: 'alarmIcon',
        content: 'waitingListSuccessAlertContent',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(`/${language}/customers/account#waitingList`);
      } else {
        if (handleClose) {
          handleClose();
        }

        Swal.close();
      }
    });
  }

  async function fetchWaitingList(inputPage) {
    const query = qs.stringify(
      limit === 'noLimited'
        ? {
            sortField: 'reference',
            sortOrder: 'descend',
          }
        : {
            page: inputPage,
            limit: LIMIT,
            sortField: 'reference',
            sortOrder: 'descend',
          }
    );

    setWaitingListLoading(true);
    try {
      const {
        data: { data = [], count },
      } = await axios.get(`${url}${query ? `?${query}` : ''}`, {
        headers: { token: userToken },
        params: { status: 'confirmed' },
      });

      setWaitingList(data);
      setTotalPage(Math.floor(count / LIMIT + (count % LIMIT > 0 ? 1 : 0)));
    } catch (error) {
      setWaitingList([]);
    } finally {
      setWaitingListLoading(false);
    }
  }

  async function createWaitingList(CarId, ParkingPlanId, handleClose) {
    setWaitingListLoading(true);
    try {
      const {
        data: { data = {} },
      } = await axios.post(
        `${url}`,
        {
          CarId,
          ParkingPlanId,
          status: 'confirmed',
        },
        { headers: { token: userToken } }
      );
      await fetchWaitingList(page);

      successAlert(data?.reference, handleClose);

      return Promise.resolve();
    } catch (error) {
      const {
        data: { error: errorMessage = '' },
      } = error.response || {};

      if (errorMessage === 'User already have a confirmed waiting') {
        return Promise.reject(t('MyAccount.WaitingList.haveConfirmedWaiting'));
      }

      if (errorMessage.includes('has an on-going booking')) {
        return Promise.reject(t('Cart.existingBooking'));
      }

      return Promise.reject(errorMessage);
    } finally {
      setWaitingListLoading(false);
    }
  }

  async function editWaitingList(waitingListId, data, handleClose) {
    setWaitingListLoading(true);
    try {
      await axios.put(`${url}/${waitingListId}`, data, { headers: { token: userToken } });

      await fetchWaitingList(page);

      Swal.close();

      if (handleClose) {
        handleClose();
      }
    } catch (error) {
      const {
        data: { error: errorMessage },
      } = error.response || {};

      Swal.fire({
        title: errorMessage,
        icon: 'error',
        confirmButtonText: t('MyAccount.iMonthly.confirm'),
        confirmButtonColor: '#fd980f',
      });
    } finally {
      setWaitingListLoading(false);
    }
  }

  async function cancelWaitingListStatus(waitingListId) {
    Swal.fire({
      position: 'top',
      title: t('MyAccount.WaitingList.cancelWaiting'),
      text: t('MyAccount.WaitingList.confirmCancel'),
      confirmButtonColor: '#fd980f',
      confirmButtonText: t('MyAccount.iMonthly.confirm'),
      showCancelButton: true,
      cancelButtonText: t('MyAccount.iMonthly.cancel'),
      cancelButtonColor: '#888',
      reverseButtons: true,
      preConfirm: async () => {
        await editWaitingList(waitingListId, {
          status: 'cancelled',
        });
      },
    });
  }

  useEffect(() => {
    if (!userToken || !customerId) {
      return;
    }
    fetchWaitingList(page);
  }, [userToken, customerId, page]);

  return {
    waitingList,
    totalPage,
    waitingListLoading,
    cancelWaitingListStatus,
    createWaitingList,
    fetchWaitingList,
    changePage,
    page,
    editWaitingList,
  };
}

// eslint-disable-next-line import/prefer-default-export
export default useWaitingList;
